import { ReactNode } from "react";
import { CookieCategorieEnum } from "../types/cookie-consent-helper";
import { useConsent, useConsentCategory } from "@utmb/contexts/ConsentContext";
import { Skeleton } from "../placeholder/Placeholder";
import { useTranslation } from "next-i18next";
import { Button } from "../button/Button";
import { getColorStyle } from "@utmb/utils/colors";
import { ColorTypesEnum } from "@utmb/types/colors";
import styles from './consent-aware.module.scss';

interface ConsentAwareProps {
  aspectRatio?: string;
  category: CookieCategorieEnum | CookieCategorieEnum[];
  children: ReactNode;
}

const ConsentAware = ({
  category,
  children,
  aspectRatio,
}: ConsentAwareProps) => {
  const { isConsented, isLoaded, missingCategories } =
    useConsentCategory(category);
  const { openConsentManager } = useConsent();
  const { t } = useTranslation();

  //const categoryIds = Array.isArray(category) ? category : [category];

  return !isLoaded ? (
    <Skeleton />
  ) : (
    <>
      {isConsented ? (
        children
      ) : (
        <div className={styles.container} style={{ aspectRatio }}>
          <h1>{t('consent.missing.title')}</h1>
          <div>
            <p>{t('consent.missing.intro')}</p>
            <ul>
              {missingCategories.map((cat) => (
                <li key={`consentwrappercat${cat}`}>
                  {t(`consent.missing.${cat}`)}
                </li>
              ))}
            </ul>
            <p>{t('consent.missing.footer')}</p>
          </div>
                <Button.Default 
                    color={getColorStyle(ColorTypesEnum.WHITE)}
                    bgcolor={getColorStyle(ColorTypesEnum.PRIMARY)}
                    label={t(`consent.openConsentLink`)}
                    onPress={openConsentManager}
                />
            </div>
	  )}
        </>
    );
};

export default ConsentAware;

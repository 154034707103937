import { ReactElement } from 'react';
// Next
import dynamic from 'next/dynamic';
// Interfaces
import { IconsEnum, IconsProps } from '@utmb/types/icons';

const icons = {
    [IconsEnum.ADVICE]: dynamic(() => import('./svgs/Advice')),
    [IconsEnum.ARTICLE]: dynamic(() => import('./svgs/Article')),
    [IconsEnum.ATTACHEMENT]: dynamic(() => import('./svgs/Attachement')),
    [IconsEnum.BEACH]: dynamic(() => import('./svgs/BeachIcon')),
    [IconsEnum.BOOKMARK_CHECKED]: dynamic(() => import('./svgs/BookmarkChecked')),
    [IconsEnum.BURGER]: dynamic(() => import('./svgs/Burger')),
    [IconsEnum.CALENDAR]: dynamic(() => import('./svgs/Calendar')),
    [IconsEnum.CAMERA]: dynamic(() => import('./svgs/Camera')),
    [IconsEnum.CHECKBOX]: dynamic(() => import('./svgs/Checkbox')),
    [IconsEnum.CHEVRON_DOWN]: dynamic(() => import('./svgs/ChevronDown')),
    [IconsEnum.CHEVRON_LEFT]: dynamic(() => import('./svgs/ChevronLeft')),
    [IconsEnum.CHEVRON_RIGHT]: dynamic(() => import('./svgs/ChevronRight')),
    [IconsEnum.CHEVRON_UNCONSTRAINED]: dynamic(() => import('./svgs/ChevronUnconstrained')),
    [IconsEnum.CHEVRON_UP]: dynamic(() => import('./svgs/ChevronUp')),
    [IconsEnum.CHEVRON]: dynamic(() => import('./svgs/Chevron')),
    [IconsEnum.CLOSE]: dynamic(() => import('./svgs/Close')),
    [IconsEnum.CLIPBOARD]: dynamic(() => import('./svgs/Clipboard')),
    [IconsEnum.COASTAL_PATH]: dynamic(() => import('./svgs/CoastalPathIcon')),
    [IconsEnum.DAY_TIME]: dynamic(() => import('./svgs/DayTime')),
    [IconsEnum.DESERT]: dynamic(() => import('./svgs/DesertIcon')),
    [IconsEnum.DISCOVER]: dynamic(() => import('./svgs/Discover')),
    [IconsEnum.DOWN]: dynamic(() => import('./svgs/Down')),
    [IconsEnum.DOWNLOAD]: dynamic(() => import('./svgs/Download')),
    [IconsEnum.EVENT_CENTER]: dynamic(() => import('./svgs/EventCenter')),
    [IconsEnum.EXCITED]: dynamic(() => import('./svgs/Excited')),
    [IconsEnum.EXCEL]: dynamic(() => import('./svgs/Excel')),
    [IconsEnum.FLAG]: dynamic(() => import('./svgs/Pen')),
    [IconsEnum.GLACIER]: dynamic(() => import('./svgs/GlacierIcon')),
    [IconsEnum.HEART]: dynamic(() => import('./svgs/Heart')),
    [IconsEnum.HELP]: dynamic(() => import('./svgs/Help')),
    [IconsEnum.HIGH_ALTITUDE]: dynamic(() => import('./svgs/HighAltitudeIcon')),
    [IconsEnum.HIKING_TRAIL]: dynamic(() => import('./svgs/HikingTrailIcon')),
    [IconsEnum.HILL]: dynamic(() => import('./svgs/HillIcon')),
    [IconsEnum.HOME]: dynamic(() => import('./svgs/Home')),
    [IconsEnum.INFO]: dynamic(() => import('./svgs/Info')),
    [IconsEnum.INTERVIEW]: dynamic(() => import('./svgs/Interview')),
    [IconsEnum.INVOLVED]: dynamic(() => import('./svgs/Involved')),
    [IconsEnum.JUNGLE]: dynamic(() => import('./svgs/JungleIcon')),
    [IconsEnum.LEFT]: dynamic(() => import('./svgs/Left')),
    [IconsEnum.LEGEND]: dynamic(() => import('./svgs/Legend')),
    [IconsEnum.LIVE]: dynamic(() => import('./svgs/Live')),
    [IconsEnum.LOGO_SIMPLIFIED_DARK]: dynamic(() => import('./svgs/LogoSimplifiedDark')),
    [IconsEnum.LOGO_SIMPLIFIED]: dynamic(() => import('./svgs/LogoSimplified')),
    [IconsEnum.LOGO_FINALS]: dynamic(() => import('./svgs/LogoFinals')),
    [IconsEnum.LOGO_MAJOR]: dynamic(() => import('./svgs/LogoMajor')),
    [IconsEnum.LOGOUT]: dynamic(() => import('./svgs/Logout')),
    [IconsEnum.MAG]: dynamic(() => import('./svgs/Mag')),
    [IconsEnum.MOUNTAIN]: dynamic(() => import('./svgs/MountainIcon')),
    [IconsEnum.MY_ACHIEVEMENT]: dynamic(() => import('./svgs/MyAchievement')),
    [IconsEnum.MY_DASHBOARD]: dynamic(() => import('./svgs/MyDashboard')),
    [IconsEnum.MY_REGISTRATION]: dynamic(() => import('./svgs/MyRegistration')),
    [IconsEnum.MY_INFORMATION]: dynamic(() => import('./svgs/MyInformation')),
    [IconsEnum.NEXT]: dynamic(() => import('./svgs/Next')),
    [IconsEnum.NIGHT_TIME]: dynamic(() => import('./svgs/NightTimeIcon')),
    [IconsEnum.NO]: dynamic(() => import('./svgs/No')),
    [IconsEnum.PEN]: dynamic(() => import('./svgs/Pen')),
    [IconsEnum.PIN]: dynamic(() => import('./svgs/Pin')),
    [IconsEnum.QUOTE]: dynamic(() => import('./svgs/Quote')),
    [IconsEnum.RACE_FLAG]: dynamic(() => import('./svgs/RaceFlag')),
    [IconsEnum.RACE]: dynamic(() => import('./svgs/Race')),
    [IconsEnum.RANDOM]: dynamic(() => import('./svgs/Random')),
    [IconsEnum.REFUELING_STAND]: dynamic(() => import('./svgs/RefuelingStand')),
    [IconsEnum.RIGHT]: dynamic(() => import('./svgs/Right')),
    [IconsEnum.ROAD]: dynamic(() => import('./svgs/RoadIcon')),
    [IconsEnum.RUNNER]: dynamic(() => import('./svgs/UtmbRunner')),
    [IconsEnum.RUNNING_STONES]: dynamic(() => import('./svgs/RunningStones')),
    [IconsEnum.SEARCH]: dynamic(() => import('./svgs/Search')),
    [IconsEnum.SHARE]: dynamic(() => import('./svgs/Share')),
    [IconsEnum.SHOP]: dynamic(() => import('./svgs/Shop')),
    [IconsEnum.SINGLE_TRACK]: dynamic(() => import('./svgs/SingleTrackIcon')),
    [IconsEnum.SMILEY_LAUGH]: dynamic(() => import('./svgs/SmileyLaugh')),
    [IconsEnum.SMILEY_SHY]: dynamic(() => import('./svgs/SmileyShy')),
    [IconsEnum.SMILEY_SURPRISED]: dynamic(() => import('./svgs/SmileySurprised')),
    [IconsEnum.SOCIAL_DAILYMOTION]: dynamic(() => import('./svgs/DailyMotion')),
    [IconsEnum.SOCIAL_FACEBOOK]: dynamic(() => import('./svgs/Facebook')),
    [IconsEnum.SOCIAL_INSTAGRAM]: dynamic(() => import('./svgs/Instagram')),
    [IconsEnum.SOCIAL_LINKEDIN]: dynamic(() => import('./svgs/LinkedIn')),
    [IconsEnum.SOCIAL_STRAVA]: dynamic(() => import('./svgs/Strava')),
    [IconsEnum.SOCIAL_TIKTOK]: dynamic(() => import('./svgs/TikTok')),
    [IconsEnum.SOCIAL_TWITTER]: dynamic(() => import('./svgs/Twitter')),
    [IconsEnum.SOCIAL_WECHAT]: dynamic(() => import('./svgs/WeChat')),
    [IconsEnum.SOCIAL_X]: dynamic(() => import('./svgs/SocialX')),
    [IconsEnum.SOCIAL_YOUTUBE]: dynamic(() => import('./svgs/Youtube')),
    [IconsEnum.SPINNER]: dynamic(() => import('./svgs/Spinner')),
    [IconsEnum.STAR_BORDER]: dynamic(() => import('./svgs/StarBorder')),
    [IconsEnum.STAR]: dynamic(() => import('./svgs/Star')),
    [IconsEnum.STONE_EVENTS]: dynamic(() => import('./svgs/StoneEvents')),
    [IconsEnum.STONE_MAJORS]: dynamic(() => import('./svgs/StoneMajors')),
    [IconsEnum.THUMB_UP]: dynamic(() => import('./svgs/ThumbUp')),
    [IconsEnum.TRAIN]: dynamic(() => import('./svgs/Train')),
    [IconsEnum.TRASH]: dynamic(() => import('./svgs/Trash')),
    [IconsEnum.TRASH_SECOND]: dynamic(() => import('./svgs/TrashSecond')),
    [IconsEnum.TUNNEL]: dynamic(() => import('./svgs/Tunnel')),
    [IconsEnum.UP]: dynamic(() => import('./svgs/Up')),
    [IconsEnum.USER]: dynamic(() => import('./svgs/User')),
    [IconsEnum.UTMB_FINAL]: dynamic(() => import('./svgs/UtmbFinal')),
    [IconsEnum.UTMB_GROUP]: dynamic(() => import('./svgs/UtmbGroup')),
    [IconsEnum.UTMB_RUNNER]: dynamic(() => import('./svgs/UtmbRunner')),
    [IconsEnum.WOODS]: dynamic(() => import('./svgs/WoodIcon')),
};

export const Icon = (props: IconsProps): ReactElement => {
    const { color = 'currentColor', variant, clss } = props;
    if (typeof icons[variant] !== 'undefined') {
        const Icon = icons[variant];
        return <Icon color={color} clss={clss} />;
    }
    return <></>;
};

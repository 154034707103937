import { IconEnum } from './enum/IconEnum';

export enum IconsEnum {
    ADVICE = 'advice',
    ARTICLE = 'article',
    ATTACHEMENT = 'attachement',
    BEACH = 'beach',
    BOOKMARK_CHECKED = 'bookmarkChecked',
    BURGER = 'burger',
    CALENDAR = 'calendar',
    CAMERA = 'camera',
    CHECKBOX = 'checkbox',
    CHEVRON = 'chevron',
    CHEVRON_DOWN = 'chevronDown',
    CHEVRON_LEFT = 'chevronLeft',
    CHEVRON_RIGHT = 'chevronRight',
    CHEVRON_UNCONSTRAINED = 'chevronUnconstrained',
    CHEVRON_UP = 'chevronUp',
    CLOSE = 'close',
    CLIPBOARD = 'clipboard',
    COASTAL_PATH = 'coastalPath',
    DAY_TIME = 'dayTime',
    DESERT = 'desert',
    DISCOVER = 'discover',
    DOWN = 'down',
    DOWNLOAD = 'download',
    EVENT_CENTER = 'eventCenter',
    EXCITED = 'excited',
    EXCEL = 'excel',
    FLAG = 'flag',
    GLACIER = 'glacier',
    HEART = 'heart',
    HELP = 'help',
    HIGH_ALTITUDE = 'highAltitude',
    HIKING_TRAIL = 'hikingTrail',
    HILL = 'hill',
    HOME = 'home',
    INFO = 'info',
    INTERVIEW = 'interview',
    INVOLVED = 'involved',
    JUNGLE = 'jungle',
    LEFT = 'left',
    LEGEND = 'legend',
    LIVE = 'live',
    LOGO_SIMPLIFIED = 'logoSimplified',
    LOGO_SIMPLIFIED_DARK = 'logoSimplifiedDark',
    LOGO_FINALS = 'logoFinals',
    LOGO_MAJOR = 'logoMajor',
    LOGOUT = 'logout',
    MAG = 'mag',
    MOUNTAIN = 'mountain',
    MY_ACHIEVEMENT = 'myAchievement',
    MY_DASHBOARD = 'myDashboard',
    MY_INFORMATION = 'myInformation',
    MY_REGISTRATION = 'myRegistration',
    NEXT = 'next',
    NIGHT_TIME = 'nightTime',
    NO = 'no',
    PEN = 'pen',
    PIN = 'pin',
    QUOTE = 'quote',
    RACE = 'race',
    RACE_FLAG = 'raceFlag',
    RANDOM = 'random',
    REFUELING_STAND = 'refuelingStand',
    RIGHT = 'right',
    ROAD = 'road',
    RUNNER = 'runner',
    RUNNING_STONES = 'runningStones',
    SEARCH = 'search',
    SHARE = 'share',
    SHOP = 'shop',
    SINGLE_TRACK = 'singleTrack',
    SMILEY_LAUGH = 'smileyLaugh',
    SMILEY_SHY = 'smileyShy',
    SMILEY_SURPRISED = 'smileySurprised',
    SOCIAL_DAILYMOTION = 'dailymotion',
    SOCIAL_FACEBOOK = 'facebook',
    SOCIAL_INSTAGRAM = 'instagram',
    SOCIAL_LINKEDIN = 'linkedin',
    SOCIAL_STRAVA = 'strava',
    SOCIAL_TIKTOK = 'tiktok',
    SOCIAL_TWITTER = 'twitter',
    SOCIAL_WECHAT = 'wechat',
    SOCIAL_X = 'socialX',
    SOCIAL_YOUTUBE = 'youtube',
    SPINNER = 'spinner',
    STAR = 'star',
    STAR_BORDER = 'starBorder',
    STONE_EVENTS = 'stone_events',
    STONE_MAJORS = 'stone_majors',
    THUMB_UP = 'thumbUp',
    TRAIN = 'train',
    TRASH = 'trash',
    TRASH_SECOND = 'trashSecond',
    TUNNEL = 'tunnel',
    UP = 'up',
    USER = 'user',
    UTMB_FINAL = 'utmbFinal',
    UTMB_GROUP = 'utmbGroup',
    UTMB_RUNNER = 'utmbRunner',
    WOODS = 'woods',
}

export interface IconsProps {
    variant: IconsEnum | IconEnum;
    color?: string;
    clss?: string;
}

export interface IconSvgProps {
    color?: string;
    clss?: string;
}

// React
import { ReactElement } from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
// Next
import type { AppContext, AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { useRouter } from 'next/router';
import Head from 'next/head';
// Contexts
import { AppProviders } from '../contexts';
import { AuthProvider } from '@utmb/contexts/AuthContext';
import { DashboardProvider } from '@utmb/contexts/DashboardContext';
import { TenancyProvider } from '@utmb/contexts/TenancyContext';
import { GlobalProvider } from '@utmb/contexts/GlobalContext';
import { CountryProvider } from '@utmb/contexts/CountryContext';
// Types
import { LangEnum } from '../types';
import { IncomingMessage } from 'http';
// Utils
import CookiePro from '@utmb/features/gtm/cookiePro';
import { Gtm } from '@utmb/features/gtm/gtm';
import { fetchAPI, getTenantId } from '@utmb/utils/utmb';
import { useCloudinary } from 'src/design-system/use-cloudinary/UseCloudinary';
import isMobile from '@utmb/utils/is-mobile';
// Styles
import '../styles/main.scss';
import '../styles/global.scss';
import '../styles/layout.scss';
import 'flag-icons/css/flag-icons.min.css';
import { PreferenceProvider } from '@utmb/contexts/PreferenceContext';
import { StickyPlayer } from '@utmb/features/layout/sticky-player/StickyPlayer';
import { ConsentProvider } from '@utmb/contexts/ConsentContext';

function UTMBApp({ Component, pageProps, tenantId }: AppProps & { tenantId: string }): ReactElement {
    const { locale } = useRouter();
    const { getImage } = useCloudinary();
    let favicon = null;

    if (pageProps.event?.favicon?.publicId) {
        favicon = getImage(pageProps.event.favicon.publicId);
    }

    // noinspection HtmlRequiredTitleElement
    return (
        <AppProviders initialLocale={locale}>
            <TenancyProvider tenantId={tenantId}>
                <GlobalProvider global={pageProps}>
                    <CookiePro />
                    <ConsentProvider>
                        <PreferenceProvider>
                            <Gtm />
                            <AuthProvider>
                                <DashboardProvider>
                                    <CountryProvider>
                                        <Head>
                                            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" key="key-viewport" />
                                            <meta property="og:locale" content={locale} key="key-og" />
                                            <meta name="rating" content="general" key="key-rating" />
                                            <link rel="shortcut icon" href={favicon ?? '/favicon.ico'} key="key-favicon" />
                                        </Head>
                                        <SimpleReactLightbox>
                                            <Component {...pageProps} />
                                        </SimpleReactLightbox>
                                    </CountryProvider>
                                </DashboardProvider>
                            </AuthProvider>
                        </PreferenceProvider>
                        <StickyPlayer />
                    </ConsentProvider>
                </GlobalProvider>
            </TenancyProvider>
        </AppProviders>
    );
}

UTMBApp.getInitialProps = async ({ ctx: { req, locale, defaultLocale } }: AppContext) => {
    try {
        const lang: LangEnum = (locale || defaultLocale || i18n.defaultLocale) as LangEnum;

        const tenantId = getTenantId(req as IncomingMessage);

        // Global
        const pageProps = await fetchAPI(req as IncomingMessage, '/content/global', { lang });
        pageProps.isMobile = isMobile(req);

        return { pageProps, tenantId };
    } catch (error) {
        return {
            notFound: true,
        };
    }
};

// noinspection JSUnusedGlobalSymbols
export default appWithTranslation(UTMBApp);

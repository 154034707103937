import { useEffect, useState } from 'react';

// Reminder : Categories of cookies
export enum CookieCategorieEnum {
  FUNCTIONNAL = 'C0003',
  NECESSARY = 'C0001',
  PERFORMANCE = 'C0002',
  SOCIAL = 'C0005',
  TARGETING = 'C0004',
}

export interface OneTrustCookie {
  Category: string;
  CategoryId: string;
  Description: string;
  Host: string;
  LifeSpan: string;
  Name: string;
  Type: string;
}

export interface OneTrustGroup {
  CustomGroupId: string;
  FirstPartyCookies: OneTrustCookie[];
  GroupName: string;
  Hosts: Array<{
    Cookies: OneTrustCookie[];
    HostName: string;
  }>;
  Status: boolean;
}

export type ConsentState = Record<string, boolean>;

export const defaultConsent: ConsentState = { C0001: true };

// Function to retrieve all cookies by category from OneTrust
export const getCookiesByCategory = (): Record<string, string[]> => {
  if (
    typeof window === 'undefined' ||
    !window.OneTrust ||
    !window.OneTrust.GetDomainData
  ) {
    return {};
  }

  try {
    const groups: OneTrustGroup[] = window.OneTrust.GetDomainData().Groups;
    completeOneTrustGroupsFromCookie(groups);
    const cookiesByCategory: Record<string, string[]> = {};

    // For each cookies category
    groups.forEach((group) => {
      const categoryId = group.CustomGroupId;
      cookiesByCategory[categoryId] = [];

      // Add first-party cookies
      if (group.FirstPartyCookies) {
        group.FirstPartyCookies.forEach((cookie) => {
          cookiesByCategory[categoryId].push(cookie.Name);
        });
      }

      // Add third-party cookies (by host)
      if (group.Hosts) {
        group.Hosts.forEach((host) => {
          if (host.Cookies) {
            host.Cookies.forEach((cookie) => {
              cookiesByCategory[categoryId].push(cookie.Name);
            });
          }
        });
      }
    });

    return cookiesByCategory;
  } catch (error) {
    console.error('Error when retrieving cookies OneTrust:', error);
    return {};
  }
};

// Hook to use cookies categories
export const useConsentHelper = () => {
  const [cookieCategories, setCookieCategories] = useState<
    Record<string, string[]>
  >({});
  const [consentState, setConsentState] =
    useState<ConsentState>(defaultConsent);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Function to update categories
    const updateCategories = () => {
      setIsLoaded(false);
      const categories = getCookiesByCategory();
      const consent = getConsentFromCookie();
      setCookieCategories(categories);
      setConsentState(consent ?? defaultConsent);
      setIsLoaded(true);
    };

    // Check if onetrust is loaded
    if (
      typeof window !== 'undefined' &&
      window.OneTrust &&
      window.OneTrust.GetDomainData
    ) {
      updateCategories();
    } else {
      // Wait until onetrust is loaded
      const checkInterval = setInterval(() => {
        if (
          typeof window !== 'undefined' &&
          window.OneTrust &&
          window.OneTrust.GetDomainData
        ) {
          clearInterval(checkInterval);
          updateCategories();
        }
      }, 500);

      return () => clearInterval(checkInterval);
    }

    // Update categories at each consent change
    const handleConsentChange = () => {
      updateCategories();
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('consent.onetrust', handleConsentChange);

      // Intercept also OptanonWrapper
      const originalOptanonWrapper = window.OptanonWrapper;
      window.OptanonWrapper = function () {
        if (originalOptanonWrapper) originalOptanonWrapper();
        handleConsentChange();
      };
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('consent.onetrust', handleConsentChange);
      }
    };
  }, []);

  return { consentState, cookieCategories, isLoaded };
};

// Function to delete cookies that are not acceptable with the consent...
export const deleteRejectedCookies = () => {
  if (
    typeof window === 'undefined' ||
    !window.OneTrust ||
    !window.OneTrust.GetDomainData
  ) {
    return;
  }

  const cookieCategories = getCookiesByCategory();
  const groups = window.OneTrust.GetDomainData().Groups;
  completeOneTrustGroupsFromCookie(groups);

  // For each category
  Object.entries(cookieCategories).forEach(([categoryId, cookies]) => {
    const category = groups.find((g) => g.CustomGroupId === categoryId);

    // If category is refused and not the necessary category (C0001)
    if (category && !category.Status && categoryId !== 'C0001') {
      // Delete all declared cookies in this category
      cookies.forEach((cookieName) => {
        deleteCookie(cookieName);
      });
    }
  });
};

export const getConsentFromCookie = (): ConsentState | null => {
  const cookie = decodeURIComponent(
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('OptanonConsent=')) ?? '',
  );
  if (!cookie) return null;

  const cookieValue = cookie.substring(15);
  if (!cookieValue) return null;

  const groups = cookieValue
    .split('&')
    ?.find((item) => item.startsWith('groups='));
  if (!groups) return null;

  const groupData = defaultConsent;
  groups
    .replace('groups=', '')
    .split(',')
    .forEach((group) => {
      const [id, status] = group.split(':');
      if (id !== 'C0001') {
        groupData[id] = status === '1';
      }
    });

  return groupData;
};

export const completeOneTrustGroupsFromCookie = (
  groups: OneTrustGroup[],
): void => {
  const groupData = getConsentFromCookie();

  groups.forEach((group) => {
    const categoryId = group.CustomGroupId;
    if (groupData && groupData[categoryId]) group.Status = true;
    else group.Status = false;
  });
};

// Function to delete a specified cookie
export const deleteCookie = (name: string, path = '/', domain = '') => {
  if (typeof document !== 'undefined' && document.cookie.indexOf(name) >= 0) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
};